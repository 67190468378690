<template>
  <div class="allHolderCont">
    <div class="d-flex align-items-center justify-content-between">
      <h1 class="title" id="titleDesck">
        Recados
        <span>Banners de avisos no Home</span>
      </h1>
      <h1 class="title" id="titleMobile">
        <span>Banners de avisos no Home</span>
      </h1>
    </div>
    <div class="box-blue justify-content-center align-items-center row">
      <div class="col-3" id="orangeCont">
        <div class="col-12 text-center">
          <button class="btn btn-default" @click="openModal()">
            <font-awesome-icon icon="fas-light fa-plus" />
            <span class="ml-1 span-button-plus">Novo Banner</span>
          </button>
        </div>
        <div class="col-12 text-center">
          <label class="mt-2 label-info">Tamanho máximo de arquivo: 10mb</label>
        </div>
      </div>
    </div>
    <div class="justify-content-end row mt-2 info">
      Inserir até 06 banners
    </div>
    <listScraps v-if="!getNotices.load" />
    <div
      class="d-flex align-items-center justify-content-center"
      style="height: 250px"
      v-else
    >
      <half-circle-spinner
        :animation-duration="1000"
        :size="100"
        color="#fff"
      />
    </div>
    <component :is="cp" @closeModal="closeModal" />
  </div>
</template>

<script>
import listScraps from "./container/_listScraps.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters } from "vuex";
export default {
  components: {
    HalfCircleSpinner,
    listScraps,
    modalCreate: () => import("./modal/create/modalCreate.vue")
  },
  data() {
    return {
      cp: ""
    };
  },
  computed: {
    ...mapGetters(["getNotices"])
  },
  methods: {
    openModal() {
      this.cp = "modalCreate";
    },
    closeModal() {
      $(`#${this.cp}`).modal("hide");
      this.cp = "";
    }
  },
  watch: {
    cp() {
      if (this.cp != "") {
        setTimeout(() => {
          $(`#${this.cp}`).modal("show");
        }, 200);
      }
    }
  }
};
</script>

<style scoped>
.title {
  margin: 24px 0;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.title span {
  font-weight: 600;
  font-size: 16px;
  color: #98c4e6;
}

.box-blue {
  height: 185px;
  background: #1b60a8;
  border-radius: 12px;
}

.span-button-plus {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}

.btn-default {
  color: #ffffff !important;
  background-color: #f38235;
  border-radius: 6px;
}

.btn-default:hover,
.btn-default:active,
.btn-default.focus,
.btn-default:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background-color: #e0a638 !important;
}

.label-info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #ffffff;
}

.info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
@media screen and (min-width:768px){

  #titleMobile{
    display: none;
  }
}
@media screen and (max-width:767px){
  .allHolderCont{
    padding-left: 15px;
    padding-right: 15px;
  }
  #titleDesck{
    display: none;
  }
  #orangeCont{
    max-width: 100%;
    min-width: 75%;
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btn{
    height: 100%;
  }
  .label-info{
    font-size: 17px;
  }
  .box-blue{
    margin-left: 15px;
    margin-right: 15px;
  }
  .info{
    margin-right: 15px;
  }
}
</style>
