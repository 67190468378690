<template>
  <div class="tab-content" id="myTabContent">
    <tabs :tabs="tabList" :navClass="'nav-tabs-blue'" @tabsMovie="tabsMovie" />
    <scrap
      class="conteudo show active"
      :listCurrent="listCurrent"
      v-if="!load && listCurrent.length"
    />

    <div
      class="d-flex align-items-center justify-content-center"
      style="height: 200px"
      v-else-if="load"
    >
      <half-circle-spinner
        :animation-duration="1000"
        :size="100"
        color="#fff"
      />
    </div>

    <div class="justify-content-center row mt-5 info_bottom" v-else>
      Nenhum Banner no momento.
    </div>
  </div>
</template>

<script>
import tabs from "@/components/tabs/modalTabs.vue";
import scrap from "./_scrap.vue";
import { HalfCircleSpinner } from "epic-spinners";
import { mapGetters } from "vuex";
export default {
  components: {
    tabs,
    scrap,
    HalfCircleSpinner
  },
  mounted() {
    this.listCurrent = this.getNotices.result.noticesActives;
  },
  props: {},
  data() {
    return {
      tabList: [
        {
          description: "Banners Ativos",
          tabName: "activeBanner"
        },
        {
          description: "Próximos banners",
          tabName: "nextBanner"
        }
      ],
      firstTab: true,
      secondTab: false,
      listCurrent: [],
      load: false
    };
  },
  methods: {
    tabsMovie(index) {
      switch (index) {
        case 0:
          this.firstTab = true;
          this.secondTab = false;
          break;
        case 1:
          this.firstTab = false;
          this.secondTab = true;
          break;
      }
    }
  },
  watch: {
    firstTab() {
      if (this.firstTab) {
        if (!this.getNotices.result.noticesActives.length) {
          this.listCurrent = [];
          return;
        }
        this.load = true;
        setTimeout(() => {
          this.load = false;
          this.listCurrent = this.getNotices.result.noticesActives;
        }, 500);
      }
    },
    secondTab() {
      if (this.secondTab) {
        if (!this.getNotices.result.noticesPendings.length) {
          this.listCurrent = [];
          return;
        }

        this.load = true;
        setTimeout(() => {
          this.listCurrent = this.getNotices.result.noticesPendings;
          this.load = false;
        }, 500);
      }
    }
  },
  computed: {
    ...mapGetters(["getNotices"])
  }
};
</script>

<style scoped>
@media (max-width: 230px) {
  #myTabContent {
    max-width: 200;
    overflow-x: auto;
    min-width: 115px;
  }
}

#myTabContent {
  width: 100%;
}

.info_bottom {
  color: #ffffff;
  opacity: 0.2;
  color: #ffffff !important;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 48px;
}
@media screen and (max-width:767px){
  .info_bottom{
    font-size: 29px;
    margin-left: 15px;
  }
}
</style>
